import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { ajaxUrl } from "../../config";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    discorsePosts: [],
    post: null,

};

const slice = createSlice({
    name: 'discorsePost',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET POSTS
        getPostsSuccess(state, action) {
            state.isLoading = false;
            state.discorsePosts = action.payload;
        },
        createPostSuccess(state, action) {
            state.isLoading = false
            state.discorsePosts = [...state.discorsePosts, action.payload]
        },
        updatePostSuccess(state, action) {
            state.isLoading = false
            state.discorsePosts = [...state.discorsePosts.filter(post => post._id !== action.payload._id), action.payload]
        },
        deleteDiscorsePostSuccess(state, action) {
            state.isLoading = false
            state.discorsePosts = state.discorsePosts.filter(post => post._id !== action.payload)
        },

    }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllDiscorsePosts() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { data: { data } } = await axios.get(`${ajaxUrl}/discorsePost/getAllPosts`);
            dispatch(slice.actions.getPostsSuccess(data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function createDiscorsePost(formData) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { data: { data } } = await axios.post(`${ajaxUrl}/discorsePost/createDiscorsePost`, formData)
            dispatch(slice.actions.createPostSuccess(data))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function likeDiscorsePost(formData) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        let { comments, ...rest } = formData
        try {
            const { data: { data } } = await axios.post(`${ajaxUrl}/discorsePost/likeDiscorsePost`, { ...rest })
            dispatch(slice.actions.updatePostSuccess({ ...data, comments }))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function commentDiscorsePost(formData) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        // console.log({ formData })
        try {
            const { data: { data } } = await axios.post(`${ajaxUrl}/discorsePost/commentDiscorsePost`, formData)
            dispatch(slice.actions.updatePostSuccess(data))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteDiscorsePost(postId, successFunc) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post(`${ajaxUrl}/discorsePost/deleteDiscorsePost/${postId}`)
            dispatch(slice.actions.deleteDiscorsePostSuccess(postId))
            successFunc()
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}




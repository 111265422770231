import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import notificationReducer from './slices/notification';
import blogReducer from './slices/blog';
import jobPostReducer from './slices/jobPost';
import discorsePostReducer from './slices/discorseAskPost';
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import filmReducer from './slices/film';
import announcementReducer from './slices/announcement'
import uploadingProgressReducer from './slices/uploadingProgress';
import reviewReducer from './slices/review';
import spotlightReducer from './slices/spotlight'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-'
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const filmPersistConfig = {
  key: 'film',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};
const spotlightPersistConfig = {
  key: 'spotlight',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['spotlights', 'spotlight', 'loading', 'error']
};

const rootReducer = combineReducers({
  announcement: announcementReducer,
  mail: mailReducer,
  chat: chatReducer,
  notification: notificationReducer,
  blog: blogReducer,
  jobPost: jobPostReducer,
  discorsePost: discorsePostReducer,
  user: userReducer,
  calendar: calendarReducer,
  uploadingProgress: uploadingProgressReducer,
  review: reviewReducer,
  film: persistReducer(filmPersistConfig, filmReducer),
  product: persistReducer(productPersistConfig, productReducer),
  spotlight: persistReducer(spotlightPersistConfig, spotlightReducer)
});

export { rootPersistConfig, rootReducer };

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { ajaxUrl } from "../../config";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  activityFeedPosts: [],
  post: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },
    getActivityPostsSuccess(state, action) {
      state.isLoading = false;
      state.activityFeedPosts = action.payload;
    },

    createPostSuccess(state, action) {
      state.isLoading = false
      state.posts = [...state.posts, action.payload]
    },
    createActivityPostSuccess(state, action) {
      state.isLoading = false
      state.activityFeedPosts = [...state.activityFeedPosts, action.payload]
    },

    updatePostSuccess(state, action) {
      state.isLoading = false
      state.posts = [...state.posts.filter(post => post._id !== action.payload._id), action.payload]
    },

    updateActivityPostSuccess(state, action) {
      state.isLoading = false
      state.activityFeedPosts = [...state.activityFeedPosts.filter(post => post._id !== action.payload._id), action.payload]
    },

    deletePostSuccess(state, action) {
      state.isLoading = false
      state.posts = state.posts.filter(post => post._id !== action.payload)
    },
    deleteActivityPostSuccess(state, action) {
      state.isLoading = false
      state.activityFeedPosts = state.activityFeedPosts.filter(post => post._id !== action.payload)
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllPosts(authorId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.get(`${ajaxUrl}/post/getPosts/${authorId}`);
      dispatch(slice.actions.getPostsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getActivityFeedPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.get(`${ajaxUrl}/post/getAllPosts`);
      dispatch(slice.actions.getActivityPostsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createPost(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      const { data: { data } } = await axios.post(`${ajaxUrl}/post/createPost`, formData, config)
      dispatch(slice.actions.createPostSuccess(data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createActivityPost(formData, successFunc) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      const { data: { data } } = await axios.post(`${ajaxUrl}/post/createPost`, formData, config)
      dispatch(slice.actions.createActivityPostSuccess(data))
      successFunc()
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePost(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      const { data: { data } } = await axios.post(`${ajaxUrl}/post/updatePost`, formData, config)
      dispatch(slice.actions.updatePostSuccess(data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function likeActivityPost(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let { comments, ...rest } = formData
    try {
      const { data: { data } } = await axios.post(`${ajaxUrl}/post/likePost`, { ...rest })
      dispatch(slice.actions.updateActivityPostSuccess({ ...data, comments }))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function commentActivityPost(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.post(`${ajaxUrl}/post/commentPost`, formData);
      dispatch(slice.actions.updateActivityPostSuccess(data))
      // console.log(data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePost(postId, showNotification) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${ajaxUrl}/post/deletePost/${postId}`)
      dispatch(slice.actions.deletePostSuccess(postId))
      showNotification("Successfully Deleted", "success")
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteActivityPost(postId, successFunc) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${ajaxUrl}/post/deletePost/${postId}`)
      dispatch(slice.actions.deleteActivityPostSuccess(postId))
      successFunc()
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPostsInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/posts', {
        params: { index, step }
      });
      const results = response.data.results.length;
      const { maxLength } = response.data;

      dispatch(slice.actions.getPostsInitial(response.data.results));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPost(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/post', {
        params: { title }
      });
      dispatch(slice.actions.getPostSuccess(response.data.post));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError());
    }
  };
}

// ----------------------------------------------------------------------

export function getRecentPosts(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/posts/recent', {
        params: { title }
      });

      dispatch(slice.actions.getRecentPostsSuccess(response.data.recentPosts));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError());
    }
  };
}

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { map, filter } from 'lodash';
import { ajaxUrl } from '../../config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  newAnnouncement: null,
  announcements: [],
  creatorAnnouncements: [],
  screenerAnnouncements: []
};

const slice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET announcement
    getAnnouncementsSuccess(state, action) {
      state.isLoading = false;
      state.announcements = action.payload;
    },
    getCreatorAnnouncementsSuccess(state, action) {
      state.isLoading = false;
      state.creatorAnnouncements = action.payload;
    },
    getScreenerAnnouncementsSuccess(state, action) {
      state.isLoading = false;
      state.screenerAnnouncements = action.payload;
    },

    // create announcement
    createAnnouncementSuccess(state, action) {
      state.isLoading = false;
      state.newAnnouncement = action.payload;
    },

    deleteAnnouncementSuccess(state, action) {
      const { announcementId } = action.payload;
      const deleteAnnouncement = filter(state.announcements, (announcement) => announcement.id !== announcementId);
      state.isLoading = false;
      state.announcements = deleteAnnouncement;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function createAnnouncement(newAnnouncement) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${ajaxUrl}/announcements`, newAnnouncement);
      dispatch(slice.actions.createAnnouncementSuccess(response.data.announcement));
      console.log('post success');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getAnnouncements() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${ajaxUrl}/announcements`);
      dispatch(slice.actions.getAnnouncementsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCreatorAnnouncements() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${ajaxUrl}/announcements?creator=true`);
      dispatch(slice.actions.getCreatorAnnouncementsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getScreenerAnnouncements() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${ajaxUrl}/announcements?screener=true`);
      dispatch(slice.actions.getScreenerAnnouncementsSuccess(response.data));
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAnnouncement(announcementId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`${ajaxUrl}/announcements/${announcementId}`, { announcementId });
      dispatch(slice.actions.deleteAnnouncementSuccess({ announcementId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

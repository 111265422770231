import * as Yup from 'yup';
import { Box, Button, Card, Checkbox, CircularProgress, Container, FormHelperText, FormLabel, Grid, IconButton, Paper, Stack, Tab, Tabs, TextField, Typography } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { useFormik } from 'formik'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QuillEditor } from 'src/components/editor'
import { UploadFilmFile } from 'src/components/upload'
import useAuth from 'src/hooks/useAuth'
import DeleteIcon from '@material-ui/icons/Delete';
import AreYouSure from 'src/components/AreYouSure';
import { addSpotlight, deleteSpotlight, editSpotlight, getSpotlights } from 'src/redux/slices/spotlight';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

export const add3Dots = (string, limit) => {
    var dots = '...'
    if (string?.length > limit) {
        // you can also use substr instead of substring
        string = string?.substring(0, limit) + dots
    }

    return string
}

const initialState = {
    title: '',
    description: '',
    mainImage: '',
    subImage: '',
    content: '',
    isPublic: false,
    feature: false,
    vanity: ''
}

function Spotlight() {
    const dispatch = useDispatch()
    const { user } = useAuth();
    const { uploadingProgress } = useSelector(state => state.uploadingProgress)
    const { spotlights, isLoading } = useSelector(state => state.spotlight)
    const [tabValue, setTabValue] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [state, setState] = useState(initialState)
    const [openAlert, setOpenAlert] = useState(false)
    const [loadingDlt, setDltLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const handleClose = _ => {
        setOpenAlert(false)
    }
    const handleOpen = id => {
        setOpenAlert(id)
    }

    useEffect(() => {
        (async () => {
            try {
                if (tabValue === 1) {
                    setState(initialState)
                    dispatch(getSpotlights())
                }
            } catch (error) {
                console.log(error)
            }
        })()
    }, [tabValue, dispatch])

    const spotlightScheme = Yup.object().shape({
        title: Yup.string().max(124, "Max are 124 characters!").required('This is required!'),
        vanity: Yup.string().max(30, "Max are 30 characters!").required('This is required!'),
        description: Yup.string().required('This is required!'),
        mainImage: Yup.mixed().required('This is required!'),
        subImage: Yup.mixed().required('This is required!'),
        content: Yup.string().required('This is required!'),
        isPublic: Yup.boolean(),
        feature: Yup.boolean(),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: state,
        validationSchema: spotlightScheme,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                setSubmitting(true)
                if (isEdit) {
                    dispatch(editSpotlight(values, setSubmitting, enqueueSnackbar, resetForm, state, () => {
                        setState(initialState)
                        setIsEdit(false)
                    }))
                } else {
                    dispatch(addSpotlight(values, user, setSubmitting, enqueueSnackbar, resetForm))
                }
            }
            catch (err) {
                console.log(err)
                setSubmitting(false);
                setErrors(err);
            }
        }
    })
    const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

    const handleDropMainImage = useCallback(
        (acceptedFiles) => {
            setFieldValue(
                'mainImage',
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        },
        [setFieldValue]
    );

    const handleRemoveAllMainImage = () => {
        setFieldValue('mainImage', []);
    };

    const handleRemoveMainImage = (file) => {
        const filteredItems = values.poster.filter((_file) => _file !== file);
        setFieldValue('mainImage', filteredItems);
    };
    const handleDropSubImage = useCallback(
        (acceptedFiles) => {
            setFieldValue(
                'subImage',
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        },
        [setFieldValue]
    );

    const handleRemoveAllSubImage = () => {
        setFieldValue('subImage', []);
    };

    const handleRemoveSubImage = (file) => {
        const filteredItems = values.poster.filter((_file) => _file !== file);
        setFieldValue('subImage', filteredItems);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleDelete = async v => {
        try {
            setDltLoading(true)
            dispatch(deleteSpotlight(v, handleClose, setDltLoading))
        } catch (err) {
            setDltLoading(false)
            console.log(err)
        }
    }

    return (
        <Container>
            <Typography variant="h3" mb={2}>Spotlight</Typography>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" sx={{ mb: 3 }}>
                <Tab label="Create" sx={{ px: 3 }} />
                <Tab label="Update" sx={{ px: 3 }} />
            </Tabs>
            {tabValue === 0 ? <Grid container component={'form'} spacing={3} onSubmit={handleSubmit}>
                <Grid item xs={12} md={6}>
                    <TextField
                        type="text"
                        fullWidth
                        label="Title"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Add Spotlight Title"
                        SelectProps={{ native: true }}
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        type="text"
                        fullWidth
                        label="URL"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Add Spotlight Title"
                        SelectProps={{ native: true }}
                        {...getFieldProps('vanity')}
                        InputProps={{}}
                        error={Boolean(touched.vanity && errors.vanity)}
                        helperText={touched.vanity && errors.vanity}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="text"
                        fullWidth
                        label="Description"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Add Spotlight Description"
                        SelectProps={{ native: true }}
                        multiline
                        minRows={3}
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ my: 2 }}>
                        <Card sx={{ p: 1, m: 1 }}>
                            <UploadFilmFile
                                title="Banner 820 x 312"
                                fileType="img"
                                currentFile={null}
                                showPreview
                                maxSize={31457200}
                                accept="image/*"
                                files={values.mainImage || []}
                                onDrop={handleDropMainImage}
                                onRemove={handleRemoveMainImage}
                                onRemoveAll={handleRemoveAllMainImage}
                                error={Boolean(touched.mainImage && errors.mainImage)}
                            />
                            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                                {touched.mainImage && errors.mainImage}
                            </FormHelperText>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ my: 2 }}>
                        <Card sx={{ p: 1, m: 1 }}>
                            <UploadFilmFile
                                title="Profile Pic 170 x 170"
                                fileType="img"
                                currentFile={null}
                                showPreview
                                maxSize={31457200}
                                accept="image/*"
                                files={values.subImage || []}
                                onDrop={handleDropSubImage}
                                onRemove={handleRemoveSubImage}
                                onRemoveAll={handleRemoveAllSubImage}
                                error={Boolean(touched.subImage && errors.subImage)}
                            />

                            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                                {touched.subImage && errors.subImage}
                            </FormHelperText>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <label style={{ width: "100%" }}>
                            <QuillEditor
                                simple
                                noExtra
                                id="content"
                                label="Content"
                                onChange={(val) => { setFieldValue('content', val) }}
                                value={values.content}
                                placeholder="Type Spotlight Content"
                                sx={{
                                    borderColor: 'transparent',
                                    flexGrow: 1
                                }}
                            />
                        </label>
                    </Stack>
                    <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                        {touched.content && errors.content}
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ mt: 3, display: 'flex', position: 'fixed', bottom: '2.5rem', right: '7rem', minWidth: '10rem' }}>
                        <FormLabel sx={{ display: 'flex', mr: 2, alignItems: 'center' }}>
                            <Checkbox color={'primary'}
                                onChange={e => {
                                    setFieldValue('feature', e.target.checked);
                                    e.target.checked && setFieldValue('isPublic', e.target.checked);
                                }}
                                checked={values.feature}
                            />
                            Feature
                        </FormLabel>
                        <FormLabel sx={{ display: 'flex', mr: 2, alignItems: 'center' }}>
                            <Checkbox color={'primary'}
                                {...getFieldProps('isPublic')}
                                checked={values.isPublic}
                            />
                            Public
                        </FormLabel>
                        <LoadingButton type="submit" variant="contained"
                            fullWidth
                            disabled={isSubmitting && isLoading}
                            loading={uploadingProgress > 0}
                        >
                            {(isSubmitting && isLoading) && <CircularProgress value={uploadingProgress} size={20} sx={{ mr: 1 }} />}
                            {isEdit ? 'Update' : 'Save'}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid> : <Grid container gap={3} mt={1}>
                {spotlights?.map((v, i) => <Grid key={i} item container
                    xs={12} component={Card} alignItems={'center'} display={'flex'}
                    p={2}>
                    <Grid item xs={2}>
                        <Typography fontWeight={'bold'}>
                            {v.title}
                        </Typography></Grid>
                    <Grid item xs={7}>{add3Dots(
                        v?.description, 150)}

                    </Grid>
                    <Grid item xs={3} sx={{ "&>button": { mr: 1 } }} display={'flex'} justifyContent={'end'} alignItems={'center'}>
                        <Button variant='contained' onClick={() => {
                            setState(v)
                            setIsEdit(true)
                            setTabValue(0)
                        }}>Edit</Button>
                        <Button variant='contained' component={Link} to={`/spotlight/${v.vanity}`}>View</Button>
                        <IconButton onClick={() => handleOpen(v)}><DeleteIcon color={'error'} /></IconButton>
                    </Grid>
                </Grid>)}
                {!spotlights?.length && <Typography onClick={() => setTabValue(0)} sx={{ cursor: 'pointer' }}>No spotlight found! Add One.</Typography>}
            </Grid>}
            <AreYouSure openAlert={openAlert} handleDlt={handleDelete} handleClose={handleClose} loading={loadingDlt} />
        </Container>
    )
}

export default Spotlight
// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ user, ...other }) {
  const { user: mainUser } = useAuth()
  return (
    <MAvatar
      src={user ? user?.mainImage : mainUser?.mainImage}
      alt={user ? user?.firstName : mainUser?.firstName}
      // color={user ? user?.mainImage : mainUser?.mainImage ? 'default' : createAvatar(user ? (user?.firstName || user?.fullName) : (mainUser?.firstName || mainUser?.fullName)).color}
      {...other}
    >
      {createAvatar(user ? (user?.firstName || user?.fullName) : (mainUser?.firstName || mainUser?.fullName)).name}

    </MAvatar>
  );
}

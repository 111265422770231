import { io } from 'socket.io-client'
import AWS from 'aws-sdk'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const genres = [
  "Action",
  "Adventure",
  "Animated",
  "Biographical (Biopics) - or Historical",
  "Chick Flicks",
  "Childrens - Kids - Family-Oriented",
  "Classic",
  "Comedy (& Black Comedy)",
  "Courtroom Dramas",
  "Crime & Gangster",
  "Cult",
  "Detective & Mystery",
  "Disaster",
  "Documentary",
  "Drama",
  "Epics/Historical/Period",
  "Fantasy",
  "Film Noir",
  "Guy",
  "Horror",
  "LGTBQIA2S+",
  "Melodramas, Women's or Weeper, Tearjerkers",
  "Musicals (Dance)",
  "Road",
  "Romance",
  "Science Fiction",
  "Serial",
  "Silent",
  "Sports",
  "Superhero",
  "Supernatural",
  "Thriller-Suspense",
  "War (Anti-War)",
  "Westerns",
  "Zombie"
];

// const BASE_URL = "http://localhost:5002/"
// const BASE_URL = "https://api.screenindie.com/" //! Old
const BASE_URL = "https://screen-indie-backend-7d17620d0b88.herokuapp.com/"

export const ajaxUrl = `${BASE_URL}api`

export const socket = io(BASE_URL, {
  withCredentials: true
});

export const S3_BUCKET = "screen-indie-video-input"
export const S3_TRANSCODED_BUCKET = "screen-indie-video-transcoded"
const REGION = "us-east-1"
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY
const SECRET_KEY = process.env.REACT_APP_AWS_SECRET_KEY

export const awsElasticConfig = {
  region: REGION,
  videoBucket: S3_TRANSCODED_BUCKET,
  transcode: {
    video: {
      pipelineId: "1619544863473-2n7kkb",
      outputKeyPrefix: "screen_indie_launch/",
      presets: [
        { presetId: "1351620000001-100070", suffix: "_web_" }
      ]
    }
  }
}

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_KEY,
  httpOptions: {
    timeout: 120000 * 10,
    connectTimeout: 10 * 1000,
  },
})

export const s3 = new AWS.S3({
  region: REGION
})

export const transcoder = new AWS.ElasticTranscoder({
  region: awsElasticConfig.region
})
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { ajaxUrl } from '../../config';

// ----------------------------------------------------------------------

export function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: []
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;

      state.conversations.byId = objFromArray(conversations);
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.conversations.byId[conversation.id] = conversation;
        state.activeConversationId = conversation.id;
        if (!state.conversations.allIds.includes(conversation.id)) {
          state.conversations.allIds.push(conversation.id);
        }
      } else {
        state.activeConversationId = null;
      }
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId, isReceiver } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId
      };
      if (state.conversations.byId[conversationId].messages.find(message => message.id === messageId) === undefined) {
        state.conversations.byId[conversationId].messages.push(newMessage);
        if (isReceiver) {
          ++state.conversations.byId[conversationId].unreadCount
        }
      }
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },

    resetChatData(state) {
      state.isLoading = false
      state.error = false
      state.contacts = { byId: {}, allIds: [] }
      state.conversations = { byId: {}, allIds: [] }
      state.activeConversationId = null
      state.participants = []
      state.recipients = []
      state.contacts = { byId: {}, allIds: [] }
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, onSendMessage, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------

export const updateUserData = (data) => {
  let newData = data.map(res => ({
    id: res._id.toString(),
    name: res.fullName || `${res.firstName || ''} ${res.lastName || 'Nil'}`,
    username: res.email?.split('@')[0] || 'Nil',
    avatar: res.mainImage || 'Nil',
    address: res.location || 'Nil',
    phone: res.phoneNumber || 'Nil',
    email: res.email || 'Nil',
    lastActivity: res.lastActivity || 0,
    status: res.status || 'busy',
    position: res.position || 'Nil',
    role: res.role
  }));
  return newData
}

export function getContacts(user) {
  let query = "?_id=60dc098dd2ef7257b903cf57";
  user.friends.forEach((v) => {
    query = query + '&&_id=' + v;
  });
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.get(`${ajaxUrl}/user/getUserList${query}`);
      const newData = updateUserData(data);
      dispatch(slice.actions.getContactsSuccess(newData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversations(userId, sender, isAdmin = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.post(`${ajaxUrl}/chat/getConversations/${userId}`, { sender, isAdmin });
      const newData = data.map(res => {
        let response = { ...res };
        response["participants"] = updateUserData(response.participants);
        return {
          ...response,
          id: res._id.toString()
        }
      });
      dispatch(slice.actions.getConversationsSuccess(newData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(conversationKey, convoId, userId, sender) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.post(`${ajaxUrl}/chat/getConversation`, { conversationKey, convoId, userId, sender });
      const newData = { ...data };
      newData["participants"] = updateUserData(newData.participants);
      dispatch(slice.actions.getConversationSuccess({ ...newData, id: newData._id.toString() }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get(`${ajaxUrl}/chat/markAsSeen/${conversationId}`);
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.get(`${ajaxUrl}/chat/getParticipants/${conversationKey}`);
      if (data[0].username) {
        dispatch(slice.actions.getParticipantsSuccess(data));
      } else {
        const newData = { ...updateUserData(data) };
        dispatch(slice.actions.getParticipantsSuccess(newData));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetChat() {
  return (dispatch) => dispatch(slice.actions.resetChatData())
}

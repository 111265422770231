import { Outlet, useLocation } from 'react-router-dom';
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  // const isHome = pathname === '/';

  return (
    <>
      {!pathname?.includes('discorse-ask') && <MainNavbar />}
      <div>
        <Outlet />
      </div>

      {!pathname?.includes('discorse-ask') && <MainFooter />}
    </>
  );
}

// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_design'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  talent: getIcon('ic_talent'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  spotlight: getIcon('spotlight-1'),
  activity: getIcon('feed'),
};

const sidebarConfig = [
  {
    subheader: 'general',
    items: [
      {
        title: 'home',
        path: PATH_DASHBOARD.general.home,
        icon: ICONS.dashboard
      },
    ]
  },
  {
    subheader: 'Screen',
    items: [
      {
        title: 'films',
        path: PATH_DASHBOARD.screen.root,
        icon: ICONS.blog,
        children: [
          { title: 'browse', path: PATH_DASHBOARD.screen.films, icon: ICONS.Creator },
          { title: 'genre', path: PATH_DASHBOARD.screen.categories, icon: ICONS.blog },
        ]
      },
    ]
  },

 {
    subheader: 'community',
    items: [
      {
        title: 'creators',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
         { title: 'creators', path: PATH_DASHBOARD.user.cards },
        ]
      },
      {
        title: 'Spotlight',
        path: PATH_PAGE.spotlight,
        icon: ICONS.spotlight
      },
      {
        title: 'Hire Production Crew',
        path: PATH_DASHBOARD.general.talent,
        icon: ICONS.talent
      },
    ]
  },

];

export default sidebarConfig;

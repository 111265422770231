// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SCREENER = '/screener'
const ROOTS_ADMIN = '/admin'
const ROOTS_REVIEWER = '/reviewer'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  updatePassword: path(ROOTS_AUTH, '/update-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  verifyEmail: path(ROOTS_AUTH, '/verify-email')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  privacy: '/privacy-policy',
  terms: "terms-and-conditions",
  info: "/info",
  about: '/about-us',
  spotlight: '/spotlight',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  discorseAsk: '/discorse-ask'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    home: path(ROOTS_DASHBOARD, '/home'),
    stats: path(ROOTS_DASHBOARD, '/stats'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    browse: path(ROOTS_DASHBOARD, '/browse'),
    talent: path(ROOTS_DASHBOARD, '/talent'),
    activity: path(ROOTS_DASHBOARD, '/activity-feed'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),

  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    indivisualCards: path(ROOTS_DASHBOARD, '/user/profile/:id'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/edit-film/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },

  screen: {
    root: path(ROOTS_DASHBOARD, '/screen'),
    films: path(ROOTS_DASHBOARD, '/screen/films'),
    film: path(ROOTS_DASHBOARD, '/screen/film/:title'),
    favourite: path(ROOTS_DASHBOARD, '/screen/film/favourite'),
    categories: path(ROOTS_DASHBOARD, '/screen/categories'),
    genre: path(ROOTS_DASHBOARD, '/screen/categories/:category'),
    newfilm: path(ROOTS_DASHBOARD, '/screen/new-film')
  },

  film: {
    root: path(ROOTS_DASHBOARD, '/film'),
    films: path(ROOTS_DASHBOARD, '/film/films'),
    film: path(ROOTS_DASHBOARD, '/film/film/:title'),
    cast: path(ROOTS_DASHBOARD, '/film/film/portfolio-review-is-this-portfolio-too-creative'),
    newFilm: path(ROOTS_DASHBOARD, '/film/new-film')
  }
};


export const PATH_SCREENER = {
  root: ROOTS_SCREENER,
  general: {
    home: path(ROOTS_SCREENER, '/home'),
    stats: path(ROOTS_SCREENER, '/stats'),
    analytics: path(ROOTS_SCREENER, '/analytics'),
    browse: path(ROOTS_SCREENER, '/browse'),
    talent: path(ROOTS_SCREENER, '/talent'),
    activity: path(ROOTS_SCREENER, '/activity-feed'),
  },
  mail: {
    root: path(ROOTS_SCREENER, '/mail'),
    all: path(ROOTS_SCREENER, '/mail/all')
  },
  chat: {
    root: path(ROOTS_SCREENER, '/chat'),
    new: path(ROOTS_SCREENER, '/chat/new'),
    conversation: path(ROOTS_SCREENER, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_SCREENER, '/calendar'),

  user: {
    root: path(ROOTS_SCREENER, '/user'),
    profile: path(ROOTS_SCREENER, '/user/profile'),
    cards: path(ROOTS_SCREENER, '/user/cards'),
    indivisualCards: path(ROOTS_SCREENER, '/user/profile/:id'),
    list: path(ROOTS_SCREENER, '/user/list'),
    newUser: path(ROOTS_SCREENER, '/user/new'),
    editById: path(ROOTS_SCREENER, '/user/edit-film/edit'),
    account: path(ROOTS_SCREENER, '/user/account')
  },

  screen: {
    root: path(ROOTS_SCREENER, '/screen'),
    films: path(ROOTS_SCREENER, '/screen/films'),
    film: path(ROOTS_SCREENER, '/screen/film/:title'),
    favourite: path(ROOTS_SCREENER, '/screen/film/favourite'),
    categories: path(ROOTS_SCREENER, '/screen/categories'),
    genre: path(ROOTS_SCREENER, '/screen/categories/:category'),
    newfilm: path(ROOTS_SCREENER, '/screen/new-film')
  },

  film: {
    root: path(ROOTS_SCREENER, '/film'),
    films: path(ROOTS_SCREENER, '/film/films'),
    film: path(ROOTS_SCREENER, '/film/film/:title'),
    cast: path(ROOTS_SCREENER, '/film/film/portfolio-review-is-this-portfolio-too-creative'),
    newFilm: path(ROOTS_SCREENER, '/film/new-film')
  }
};


export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  general: {
    home: path(ROOTS_ADMIN, '/home'),
    logs: path(ROOTS_ADMIN, '/logs'),
    analytics: path(ROOTS_ADMIN, '/analytics'),
    browse: path(ROOTS_ADMIN, '/browse'),
    activity: path(ROOTS_ADMIN, '/activity-feed'),
    talent: path(ROOTS_ADMIN, '/talent'),
  },
  controlPanel: {
    landing_page: path(ROOTS_ADMIN, "/control-pannel"),
    reviews_page: path(ROOTS_ADMIN, "/reviews"),
    spotlight: path(ROOTS_ADMIN, "/spotlight")
  },
  announcements: {
    root: path(ROOTS_ADMIN, '/announcements'),
    announcement: path(ROOTS_ADMIN, '/announcements/announcement'),
    create: path(ROOTS_ADMIN, '/announcements/create'),
  },
  mail: {
    root: path(ROOTS_ADMIN, '/mail'),
    all: path(ROOTS_ADMIN, '/mail/all')
  },
  chat: {
    root: path(ROOTS_ADMIN, '/chat'),
    new: path(ROOTS_ADMIN, '/chat/new'),
    conversation: path(ROOTS_ADMIN, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_ADMIN, '/calendar'),

  user: {
    root: path(ROOTS_ADMIN, '/user'),
    profile: path(ROOTS_ADMIN, '/user/profile'),
    creators: path(ROOTS_ADMIN, '/user/creators'),
    indivisualCreators: path(ROOTS_ADMIN, '/user/profile/:id'),
    screeners: path(ROOTS_ADMIN, '/user/screeners'),
    admins: path(ROOTS_ADMIN, '/user/admins'),
    reviewers: path(ROOTS_ADMIN, '/user/reviewers'),
    newUser: path(ROOTS_ADMIN, '/user/new'),
    editById: path(ROOTS_ADMIN, '/user/edit-film/edit'),
    account: path(ROOTS_ADMIN, '/user/account'),
    createFilm: path(ROOTS_ADMIN, '/user/createFilm')
  },

  screen: {
    root: path(ROOTS_ADMIN, '/screen'),
    films: path(ROOTS_ADMIN, '/screen/films'),
    film: path(ROOTS_ADMIN, '/screen/film/:title'),
    favourite: path(ROOTS_ADMIN, '/screen/film/favourite'),
    categories: path(ROOTS_ADMIN, '/screen/categories'),
    genre: path(ROOTS_ADMIN, '/screen/categories/:category'),
    newfilm: path(ROOTS_ADMIN, '/screen/new-film')
  },

  film: {
    root: path(ROOTS_ADMIN, '/film'),
    films: path(ROOTS_ADMIN, '/film/films'),
    film: path(ROOTS_ADMIN, '/film/film/:title'),
    cast: path(ROOTS_ADMIN, '/film/film/portfolio-review-is-this-portfolio-too-creative'),
    newFilm: path(ROOTS_ADMIN, '/film/new-film')
  }
};

export const PATH_REVIEWER = {
  root: ROOTS_REVIEWER,
  controlPanel: {
    reviews_page: path(ROOTS_REVIEWER, "/reviews")
  },
  user: {
    profile: path(ROOTS_REVIEWER, '/user/profile'),
    account: path(ROOTS_REVIEWER, '/user/account'),
  },
  screen: {
    root: path(ROOTS_REVIEWER, '/screen'),
    films: path(ROOTS_REVIEWER, '/screen/films'),
    film: path(ROOTS_REVIEWER, '/screen/film/:title'),
    favourite: path(ROOTS_REVIEWER, '/screen/film/favourite'),
    categories: path(ROOTS_REVIEWER, '/screen/categories'),
    genre: path(ROOTS_REVIEWER, '/screen/categories/:category'),
    newfilm: path(ROOTS_REVIEWER, '/screen/new-film')
  },
  // film: {
  //   root: path(ROOTS_REVIEWER, '/film'),
  //   films: path(ROOTS_REVIEWER, '/film/films'),
  //   film: path(ROOTS_REVIEWER, '/film/film/:title'),
  //   cast: path(ROOTS_REVIEWER, '/film/film/portfolio-review-is-this-portfolio-too-creative'),
  //   newFilm: path(ROOTS_REVIEWER, '/film/new-film')
  // }
}
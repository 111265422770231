import { Icon } from '@iconify/react'
import twitterFill from '@iconify/icons-eva/twitter-fill'
import facebookFill from '@iconify/icons-eva/facebook-fill'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'

import { Link as ScrollLink } from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom'
// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  IconButton,
  Stack,
} from '@material-ui/core'
// routes
import { PATH_PAGE } from '../../routes/paths'
//
import Logo from '../../components/Logo'

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: 'FaceBook',
    icon: facebookFill,
    href: 'https://www.facebook.com/screenindie',
  },
  {
    name: 'Twitter',
    icon: twitterFill,
    href: 'https://twitter.com/screenindie',
  },
]

const LINKS = [
  {
    headline: 'Screen Indie',
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'FAQs', href: PATH_PAGE.faqs },
      { name: 'Spotlight', href: PATH_PAGE.spotlight },
      { name: 'Discorse Ask', href: PATH_PAGE.discorseAsk },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: PATH_PAGE.terms },
      { name: 'Privacy Policy', href: PATH_PAGE.privacy },
      { name: 'Info', href: PATH_PAGE.info },
    ],
  },
  {
    headline: 'Contact',
    children: [
      {
        name: 'accounts@screenindie.com',
        href: 'mailto:accounts@screenindie.com',
        component: 'a',
      },
    ],
  },
]

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}))

const currentYear = new Date().getFullYear()

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth='lg' sx={{ pt: 2 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to='move_top' spy smooth>
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </ScrollLink>
          </Grid>
          <Grid item xs={10} md={3}>
            <Typography variant='body2' sx={{ pr: { md: 5 } }}>
              Screen Indie is a social community streaming platform that centers
              on the film work of independent filmmakers. Premiering original
              content from your favorite independent filmmakers.
            </Typography>

            <Stack
              spacing={1.5}
              direction='row'
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social, index) => (
                <IconButton
                  aria-label={social.name + '-929'}
                  key={index}
                  color='primary'
                  sx={{ px: 2, py: 3 }}
                  href={social.href}
                  target='_blank'
                >
                  <Icon icon={social.icon} component />
                </IconButton>
              ))}
              <IconButton
                aria-label='icon-instagram-928'
                color='primary'
                sx={{ px: 2, py: 3 }}
                href={'https://www.instagram.com/screenindie/'}
                target='_blank'
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                aria-label='icon-youtube-927'
                color='primary'
                sx={{ px: 2, py: 3 }}
                href={
                  'https://www.youtube.com/channel/UCoE7u_6rf4AIxI7jXVmLVTw/videos'
                }
                target='_blank'
              >
                <YouTubeIcon />
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent='space-between'
            >
              {LINKS.map((list, index) => {
                const { headline, children } = list
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component='p' variant='overline'>
                      {headline}
                    </Typography>
                    {children.map((link, index) =>
                      !link.component ? (
                        <Link
                          to={link.href}
                          key={index}
                          color='inherit'
                          variant='body2'
                          component={RouterLink}
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      ) : (
                        <Link
                          component={link.component}
                          href={link.href}
                          key={index}
                          target='_blank'
                          color='inherit'
                          variant='body2'
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      )
                    )}
                  </Stack>
                )
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component='p'
          variant='body2'
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Screen Indie © 2021 - {currentYear}. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  )
}

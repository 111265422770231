import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { ajaxUrl } from '../../config';
// ----------------------------------------------------------------------


const initialState = {
  isLoading: false,
  error: false,
  reviews: []
};

const slice = createSlice({
  name: 'review',
  initialState,
  reducers: {

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NOTIFICATION
    getReviewsSuccess(state, action) {
      const reviews = action.payload
      state.reviews = reviews
      state.isLoading = false;
    },

    addReviewSuccess(state, action) {
      const review = action.payload
      state.reviews = [...state.reviews, review]
      state.isLoading = false;
    },

    updateReviewSuccess(state, action) {
      const review = action.payload
      let oldReviewIndex = state.reviews.findIndex(rev => rev.filmId === review.filmId)
      state?.reviews?.splice(oldReviewIndex, 1, review)
      state.isLoading = false;
    },
    deleteReviewSuccess(state, action) {
      const reviewFilmId = action.payload
      let oldReviewIndex = state.reviews.findIndex(rev => rev.filmId === reviewFilmId)
      state?.reviews?.splice(oldReviewIndex, 1)
      state.isLoading = false;
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReviews() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.post(`${ajaxUrl}/reviews`);
      dispatch(slice.actions.getReviewsSuccess(data));
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addReview(review, setSubmitting, enqueueSnackbar, setInstructionOpen) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.post(`${ajaxUrl}/reviews/addReview`, review);
      dispatch(slice.actions.addReviewSuccess(data));
      setSubmitting(false);
      enqueueSnackbar(`Create success, Review Added Successfully.`, { variant: 'success' });
      setInstructionOpen(false)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateReview(review, setSubmitting, enqueueSnackbar, setInstructionOpen) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.post(`${ajaxUrl}/reviews/updateReview`, review);
      dispatch(slice.actions.updateReviewSuccess(data));
      setSubmitting(false);
      enqueueSnackbar(`Update success, Review Updated Successfully.`, { variant: 'success' });
      setInstructionOpen(false)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteReview(reviewFilmId, handleClose, enqueueSnackbar) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.post(`${ajaxUrl}/reviews/deleteReview/${reviewFilmId}`);
      dispatch(slice.actions.deleteReviewSuccess(reviewFilmId));
      enqueueSnackbar(`Delete success, Review Deleted Successfully.`, { variant: 'success' });
      handleClose()
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
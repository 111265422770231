import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { ajaxUrl } from '../../config';
// ----------------------------------------------------------------------

function objFromArray(array, key = '_id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  mails: { byId: {}, allIds: [] },
  labels: []
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },

    // GET MAILS
    getMailsSuccess(state, action) {
      const mails = action.payload;
      state.isLoading = false;
      state.mails.byId = objFromArray(mails);
      state.mails.allIds = Object.keys(state.mails.byId);
    },

    // GET MAIL
    getMailSuccess(state, action) {
      const mail = action.payload;

      state.mails.byId[mail.id] = mail;
      if (!state.mails.allIds.includes(mail.id)) {
        state.mails.allIds.push(mail.id);
      }
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLabels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.get(`${ajaxUrl}/mail/getMailLabels`);
      dispatch(slice.actions.getLabelsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMails(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.post(`${ajaxUrl}/mail/getMails`, params)
      dispatch(slice.actions.getMailsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMail(mailId, userEmail) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.get(`${ajaxUrl}/mail/getMail/${mailId}/${userEmail}`)
      dispatch(slice.actions.getMailSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core'
import React from 'react'

function AreYouSure({ handleDlt, openAlert, handleClose, loading }) {

    return (
        <Dialog
            open={!!openAlert}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box sx={{ minWidth: "400px" }}>

                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        It will get deleted permanently.

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={() => handleDlt(openAlert)} color="primary" autoFocus disabled={loading}>
                        Yes
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default AreYouSure
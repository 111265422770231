// routes
import { PATH_ADMIN, PATH_REVIEWER } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_design'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  photo: getIcon('ic_kanban'),
  review: getIcon('ic_ecommerce'),
  spotlight: getIcon('spotlight-1'),
  analytics: getIcon('ic_analytics'),
  ADMIN: getIcon('ic_dashboard'),
  activity: getIcon('feed'),
  talent: getIcon('ic_talent'),
};
export const reviewerConfig = [
  {
    subheader: 'Control Panel',
    items: [
      {
        title: 'Reviews',
        path: PATH_REVIEWER.controlPanel.reviews_page,
        icon: ICONS.review
      }
    ]
  },
  {
    subheader: 'Films',
    items: [
      {
        title: 'films',
        path: PATH_REVIEWER.screen.root,
        icon: ICONS.blog,
        children: [
          { title: 'browse', path: PATH_REVIEWER.screen.films, icon: ICONS.Creator },
          { title: 'genre', path: PATH_REVIEWER.screen.categories, icon: ICONS.blog },
          { title: 'favorites', path: PATH_REVIEWER.screen.favourite, icon: ICONS.analytics },
          // { title: 'create film', path: PATH_REVIEWER.user.createFilm }
        ]
      }
    ]
  },
  {
    subheader: 'Settings',
    items: []
  }


]
export const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'home',
        path: PATH_ADMIN.general.home,
        icon: ICONS.ADMIN
      },
      {
        title: 'activity feed',
        path: PATH_ADMIN.general.activity,
        icon: ICONS.activity
      },
      {
        title: 'logs',
        path: PATH_ADMIN.general.logs,
        icon: ICONS.ADMIN
      }
    ]
  },
  {
    subheader: 'Control Panel',
    items: [
      {
        title: 'announcement',
        path: PATH_ADMIN.announcements.root,
        icon: ICONS.blog,
        children: [
          { title: 'view announcements', path: PATH_ADMIN.announcements.announcement, icon: ICONS.Creator },
          { title: 'create announcements', path: PATH_ADMIN.announcements.create, icon: ICONS.blog },
          // { title: 'manage', path: PATH_ADMIN.announcements.categories, icon: ICONS.blog },
        ]
      },
      {
        title: 'Landing Page Photo',
        path: PATH_ADMIN.controlPanel.landing_page,
        icon: ICONS.photo
      },
      {
        title: 'Reviews',
        path: PATH_ADMIN.controlPanel.reviews_page,
        icon: ICONS.review
      },
      {
        title: 'Spotlight',
        path: PATH_ADMIN.controlPanel.spotlight,
        icon: ICONS.spotlight
      }
    ]
  },
  {
    subheader: 'Films',
    items: [
      {
        title: 'films',
        path: PATH_ADMIN.screen.root,
        icon: ICONS.blog,
        children: [
          { title: 'browse', path: PATH_ADMIN.screen.films, icon: ICONS.Creator },
          { title: 'genre', path: PATH_ADMIN.screen.categories, icon: ICONS.blog },
          { title: 'favorites', path: PATH_ADMIN.screen.favourite, icon: ICONS.analytics },
          { title: 'create film', path: PATH_ADMIN.user.createFilm },
          { title: 'edit film', path: PATH_ADMIN.user.editById },
        ]
      }
      //   {
      //     title: 'my films',
      //     path: PATH_ADMIN.screen.root,
      //     icon: ICONS.user,
      //     children:[

      //       { title: 'creator', path: PATH_ADMIN.screen.categories, icon: ICONS.analytics },
      //       { title: 'create film', path: PATH_ADMIN.user.newUser },
      //     ]
      //   },
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'community',
    items: [
      // MANAGEMENT : USER
      {
        title: 'users',
        path: PATH_ADMIN.user.root,
        icon: ICONS.user,
        children: [
          { title: 'creators', path: PATH_ADMIN.user.creators },
          { title: 'screeners', path: PATH_ADMIN.user.screeners },
          { title: 'admins', path: PATH_ADMIN.user.admins },
          { title: 'reviewers', path: PATH_ADMIN.user.reviewers },
          { title: 'create user', path: PATH_ADMIN.user.newUser }
          // { title: 'edit user', path: PATH_ADMIN.user.editById },
        ]
      },
      {
        title: 'Hire Production Crew',
        path: PATH_ADMIN.general.talent,
        icon: ICONS.talent
      },

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_ADMIN.Creator.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_ADMIN.Creator.shop },
      //     { title: 'product', path: PATH_ADMIN.Creator.productById },
      //     { title: 'list', path: PATH_ADMIN.Creator.list },
      //     { title: 'create', path: PATH_ADMIN.Creator.newProduct },
      //     { title: 'edit', path: PATH_ADMIN.Creator.editById },
      //     { title: 'checkout', path: PATH_ADMIN.Creator.checkout },
      //     { title: 'invoice', path: PATH_ADMIN.Creator.invoice }
      //   ]
      // },

      // MANAGEMENT : BLOG
      // {
      //   title: 'films',
      //   path: PATH_ADMIN.film.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'films', path: PATH_ADMIN.film.films },
      //     { title: 'film', path: PATH_ADMIN.film.filmById },
      //     { title: 'cast', path: PATH_ADMIN.film.newFilm }
      //   ]
      // }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      { title: 'mail', path: PATH_ADMIN.mail.all, icon: ICONS.mail },
      { title: 'chat', path: PATH_ADMIN.chat.root, icon: ICONS.chat }
    ]
  }
];

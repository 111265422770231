import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { ajaxUrl } from "../../config";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    jobPosts: [],
    post: null,

};

const slice = createSlice({
    name: 'jobPost',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET POSTS
        getPostsSuccess(state, action) {
            state.isLoading = false;
            state.jobPosts = action.payload;
        },
        createPostSuccess(state, action) {
            state.isLoading = false
            state.jobPosts = [...state.jobPosts, action.payload]
        },
        updatePostSuccess(state, action) {
            state.isLoading = false
            state.jobPosts = [...state.jobPosts.filter(post => post._id !== action.payload._id), action.payload]
        },
        deleteJobPostSuccess(state, action) {
            state.isLoading = false
            state.jobPosts = state.jobPosts.filter(post => post._id !== action.payload)
        },

    }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllJobPosts() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { data: { data } } = await axios.get(`${ajaxUrl}/jobPost/getPosts`);
            dispatch(slice.actions.getPostsSuccess(data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function createJobPost(formData) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { data: { data } } = await axios.post(`${ajaxUrl}/jobPost/createJobPost`, formData)
            dispatch(slice.actions.createPostSuccess(data))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function likeJobPost(formData) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        let { comments, ...rest } = formData
        try {
            const { data: { data } } = await axios.post(`${ajaxUrl}/jobPost/likeJobPost`, { ...rest })
            dispatch(slice.actions.updatePostSuccess({ ...data, comments }))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function commentJobPost(formData) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        // console.log({ formData })
        try {
            const { data: { data } } = await axios.post(`${ajaxUrl}/jobPost/commentJobPost`, formData)
            dispatch(slice.actions.updatePostSuccess(data))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteJobPost(postId, successFunc) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post(`${ajaxUrl}/jobPost/deletePost/${postId}`)
            dispatch(slice.actions.deleteJobPostSuccess(postId))
            successFunc()
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}




import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box sx={{ width: 60, height: 100, ...sx }}>
      <svg xmlns="https://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100.6 100.6">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_LIGHTER} />
            <stop offset="100%" stopColor={PRIMARY_DARK} />
          </linearGradient>
          <linearGradient id="BG2" x1="100%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_DARKER} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_MAIN} />
            <stop offset="100%" stopColor={PRIMARY_LIGHTER} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M30.5,50.1c0-5.1,0-10.1,0-15.2c0-3.5,1.4-6.3,4.5-8c3.1-1.7,6.2-1.6,9.1,0.3c4.6,2.8,9.2,5.7,13.8,8.5
            c1.1,0.7,1.7,1.5,1.1,2.7c-0.7,1.3-1.9,0.8-2.8,0.2c-4.4-2.8-8.9-5.5-13.3-8.3c-2-1.3-4-1.7-6.1-0.5c-2.1,1.2-2.8,3.1-2.8,5.5
            c0.1,10,0.1,20,0,30c0,2.3,0.9,4,2.9,5.1c1.9,1.1,3.8,0.9,5.7-0.3c8.1-5.1,16.3-10.1,24.4-15.2c3.6-2.3,3.9-6.5,0.6-9.2
            c-0.9-0.8-1.5-1.6-0.7-2.6c0.9-1.2,1.9-0.7,2.9,0c4.9,3.7,4.5,11.1-0.8,14.5c-5.9,3.7-11.8,7.4-17.7,11c-2.2,1.4-4.5,2.8-6.7,4.2
            c-3.1,2-6.3,2.2-9.4,0.4c-3.1-1.8-4.5-4.6-4.5-8.2C30.5,60.1,30.5,55.1,30.5,50.1z"
          />
          <path
            fill="url(#BG2)"
            d="M50.3,100.6C22.6,100.6,0,78,0,50.3C0,22.6,22.6,0,50.3,0c1.1,0,2.1,0.9,2.1,2.1s-0.9,2.1-2.1,2.1
            C24.9,4.1,4.1,24.9,4.1,50.3s20.7,46.2,46.2,46.2c25.4,0,46.2-20.7,46.2-46.2c0-14.8-7.2-28.8-19.2-37.5c-0.9-0.7-1.1-2-0.5-2.9
            c0.7-0.9,2-1.1,2.9-0.5c13.1,9.4,20.9,24.7,20.9,40.8C100.6,78,78,100.6,50.3,100.6z"
          />
          {/* <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          /> */}
        </g>
      </svg>
    </Box>
  );
}

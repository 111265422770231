import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { ajaxUrl } from '../../config';
// ----------------------------------------------------------------------


const initialState = {
  isLoading: false,
  error: false,
  notifications: []
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NOTIFICATION
    getNotificationsSuccess(state, action) {
      const notifications = action.payload
      state.isLoading = false
      state.notifications = notifications
    },

    addNotificationSuccess(state, action) {
      const notification = action.payload
      if (state.notifications.find(noti => noti.createdAt === notification.createdAt) === undefined) {
        state.notifications = [...state.notifications, notification]
      }
    },

    updateNotificationSuccess(state, action) {
      const notificationId = action.payload
      if (notificationId === "all") {
        state.notifications = state.notifications.map(notification => ({ ...notification, isUnRead: false }))
      } else {
        state.notifications.find(notification => notification._id === notificationId).isUnRead = false
      }
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNotifications(userEmail) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.get(`${ajaxUrl}/notification/getNotifications/${userEmail}`);
      dispatch(slice.actions.getNotificationsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addNotification(notificationData) {
  return async (dispatch) => {
    dispatch(slice.actions.addNotificationSuccess(notificationData));
  };
}

export function updateNotification(notificationId) {
  return async (dispatch) => {
    dispatch(slice.actions.updateNotificationSuccess(notificationId));
  };
}
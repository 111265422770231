// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'


const firebaseConfig = {
    apiKey: "AIzaSyAQIgonZSgO6dvhfT5j1JiFdkB-BB9krNk",
    authDomain: "screenindie.com",
    projectId: "screen-indie",
    storageBucket: "screen-indie.appspot.com",
    messagingSenderId: "352322180148",
    appId: "1:352322180148:web:f204d2793f8e46bbcaa661"
}

initializeApp(firebaseConfig)

const auth = getAuth()


export { auth }

import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts

import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import ScreenerLayout from '../layouts/screener';
import AdminLayout from '../layouts/Admin';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';

import LoadingScreen from '../components/LoadingScreen';
import Spotlight from 'src/pages/admin/Spotlight';
// import GiveUserReview from 'src/pages/authentication/GiveUserReview';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'update-password', element: <UpdatePassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'verify-email', element: <VerifyEmail /> },
        { path: 'user-review/:bookingId/:givenByUserId', element: <GiveUserReview /> },
      ]
    },

    { path: 'movies', element: <Movies /> },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['admin', 'creator','guest']}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/dashboard/home" replace /> },
        { path: 'home', element: <GeneralApp /> },
        { path: 'activity-feed', element: <ActivityFeed /> },
        { path: 'stats', element: <GeneralCreator /> },
        {
          path: 'browse',
          element: <BrowseMovies />
        },
        {
          path: 'talent',
          element: <Talent />
        },
        { path: "book/:id", element: <BookNow /> },
        {
          path: 'user',
          children: [
            { path: '', element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'profile/:id', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserFilmCreate /> },
            { path: ':name/edit', element: <UserFilmCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'screen',
          children: [
            { path: '', element: <Navigate to="/dashboard/screen/films" replace /> },
            { path: 'films', element: <ScreenFilms /> },
            { path: 'categories', element: <Categories /> },
            { path: 'categories/:category', element: <Genre /> },
            { path: 'film/:title', element: <ScreenFilm /> },
            { path: 'film/favourite', element: <FavouriteFilm /> },
            { path: 'new-film', element: <ScreenNewFilm /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { path: '', element: <Navigate to="/dashboard/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        {
          path: 'chat',
          children: [
            { path: '', element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> },

      ]
    },

    {
      path: 'screener',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['admin', 'screener']}>
            <ScreenerLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/screener/home" replace /> },
        { path: 'home', element: <ScreenerGeneralApp /> },
        { path: 'stats', element: <GeneralCreator /> },
        { path: 'activity-feed', element: <ActivityFeed /> },
        {
          path: 'browse',
          element: <ScreenerBrowseMovies />
        },
        {
          path: 'talent',
          element: <Talent />
        },
        { path: "book/:id", element: <BookNow /> },
        {
          path: 'user',
          children: [
            { path: 'screener', element: <Navigate to="/screener/user" replace /> },
            { path: 'profile', element: <ScreenerProfile /> },
            { path: 'profile/:id', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserFilmCreate /> },
            { path: ':name/edit', element: <UserFilmCreate /> },
            { path: 'account', element: <ScreenerAccount /> }
          ]
        },
        {
          path: 'screen',
          children: [
            { path: '', element: <Navigate to="/screener/screen/films" replace /> },
            { path: 'films', element: <ScreenFilms /> },
            { path: 'categories', element: <Categories /> },
            { path: 'categories/:category', element: <Genre /> },
            { path: 'film/:title', element: <ScreenFilm /> },
            { path: 'film/favourite', element: <FavouriteFilm /> },
            { path: 'new-film', element: <ScreenNewFilm /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { path: '', element: <Navigate to="/screener/mail/all" replace /> },
            { path: 'label/:customLabel', element: <ScreenerMail /> },
            { path: 'label/:customLabel/:mailId', element: <ScreenerMail /> },
            { path: ':systemLabel', element: <ScreenerMail /> },
            { path: ':systemLabel/:mailId', element: <ScreenerMail /> }
          ]
        },
        {
          path: 'chat',
          children: [
            { path: '', element: <ScreenerChat /> },
            { path: 'new', element: <ScreenerChat /> },
            { path: ':conversationKey', element: <ScreenerChat /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> }
      ]
    },

    // admin routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['admin']}>
            <AdminLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/admin/home" replace /> },
        { path: 'home', element: <AdminDash /> },
        { path: 'logs', element: <Logs /> },
        { path: 'activity-feed', element: <ActivityFeed /> },
        {
          path: 'announcements',
          children: [
            { path: 'admin', element: <Navigate to="/admin/announcements" replace /> },
            { path: 'announcement', element: <GeneralAdmin /> },
            { path: 'create', element: <Create /> },
          ]
        },
        {
          path: 'control-pannel',
          children: [
            { path: '', element: <LandingPagePhoto /> },

          ]
        },
        {
          path: 'reviews',
          element: <Reviews />
        },
        {
          path: 'browse',
          element: <BrowseMovies />
        },
        {
          path: 'spotlight',
          element: <Spotlight />
        },
        {
          path: 'talent',
          element: <Talent />
        },
        { path: "book/:id", element: <BookNow /> },
        {
          path: 'user',
          children: [
            { path: 'admin', element: <Navigate to="/admin/user" replace /> },
            { path: 'profile', element: <AdminProfile /> },
            { path: 'profile/:id', element: <UserProfile /> },
            { path: 'creators', element: <CreatorUserList /> },
            { path: 'screeners', element: <AdminUserList /> },
            { path: 'admins', element: <AdminList /> },
            { path: 'reviewers', element: <ReviewerUserList /> },
            { path: 'new', element: <EditUser /> },
            { path: 'createFilm', element: <UserFilmCreate /> },
            { path: 'edit-film/edit', element: <UserFilmCreate /> },
            { path: ':userId/edit', element: <EditUser /> },
            { path: 'account', element: <AdminAccount /> }
          ]
        },
        {
          path: 'screen',
          children: [
            { path: '', element: <Navigate to="/screener/screen/films" replace /> },
            { path: 'films', element: <ScreenFilms /> },
            { path: 'categories', element: <Categories /> },
            { path: 'categories/:category', element: <Genre /> },
            { path: 'film/:title', element: <ScreenFilm /> },
            { path: 'film/favourite', element: <FavouriteFilm /> },
            { path: 'new-film', element: <ScreenNewFilm /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { path: '', element: <Navigate to="/screener/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        {
          path: 'chat',
          children: [
            { path: '', element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> }
      ]
    },
    // Reviewer Routes
    {
      path: 'reviewer',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['reviewer']}>
            <AdminLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'reviews',
          element: <Reviews />
        },
        {
          path: 'user',
          children: [
            { path: 'reviewer', element: <Navigate to="/reviewer/user" replace /> },
            { path: 'profile', element: <AdminProfile /> },
            { path: 'account', element: <AdminAccount /> }
          ]
        },
        {
          path: 'screen',
          children: [
            { path: '', element: <Navigate to="/screener/screen/films" replace /> },
            { path: 'films', element: <ScreenFilms /> },
            { path: 'categories', element: <Categories /> },
            { path: 'categories/:category', element: <Genre /> },
            { path: 'film/:title', element: <ScreenFilm /> },
            { path: 'film/favourite', element: <FavouriteFilm /> },
            { path: 'new-film', element: <ScreenNewFilm /> }
          ]
        },
      ]
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },

        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '',
      element: <MainLayout />,
      children: [
        { path: '', element: <LandingPage /> },
        { path: 'about-us', element: <About /> },
        { path: 'spotlight/:vanity', element: <MainSpotlight /> },
        { path: 'spotlight', element: <MainSpotlight /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'terms-and-conditions', element: <TermsConditions /> },
        { path: 'info', element: <Info /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        {
          path: 'discorse-ask',
          element: <AuthGuard>
            <DiscorseAsk />
          </AuthGuard>
        },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const UpdatePassword = Loadable(lazy(() => import('../pages/authentication/UpdatePassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const VerifyEmail = Loadable(lazy(() => import('../pages/authentication/VerifyEmail')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const ActivityFeed = Loadable(lazy(() => import('../pages/dashboard/ActivityFeed/ActivityFeed.js')));
const GeneralCreator = Loadable(lazy(() => import('../pages/dashboard/GeneralCreator')));
const BrowseMovies = Loadable(lazy(() => import('../pages/dashboard/BrowseMovies')));
const Talent = Loadable(lazy(() => import('../pages/dashboard/Talent')));
const BookNow = Loadable(lazy(() => import('../pages/dashboard/BookNow')));
const GiveUserReview = Loadable(lazy(() => import('../pages/authentication/GiveUserReview')));
// const CreatorShop = Loadable(lazy(() => import('../pages/dashboard/CreatorShop')));
// const CreatorFilmDetails = Loadable(lazy(() => import('../pages/dashboard/CreatorProductDetails')));
// const CreatorList = Loadable(lazy(() => import('../pages/dashboard/CreatorProductList')));
// const CreatorProductCreate = Loadable(lazy(() => import('../pages/dashboard/CreatorProductCreate')));
// const CreatorCheckout = Loadable(lazy(() => import('../pages/dashboard/CreatorCheckout')));
// const CreatorInvoice = Loadable(lazy(() => import('../pages/dashboard/CreatorInvoice')));
const Categories = Loadable(lazy(() => import('../pages/dashboard/Categories')));
const Genre = Loadable(lazy(() => import('../pages/dashboard/Genre')));
const ScreenFilms = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const ScreenFilm = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const FavouriteFilm = Loadable(lazy(() => import('../pages/dashboard/FavouriteFilm')));
const ScreenNewFilm = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserFilmCreate = Loadable(lazy(() => import('../pages/dashboard/UserFilmCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Movies = Loadable(lazy(() => import('../pages/dashboard/Movies')))

//***screener dash */

const ScreenerGeneralApp = Loadable(lazy(() => import('../pages/screener/GeneralApp')));
// const GeneralScreener = Loadable(lazy(() => import('../pages/screener/GeneralCreator')));
const ScreenerBrowseMovies = Loadable(lazy(() => import('../pages/screener/BrowseMovies')));
// const ScreenerCategories = Loadable(lazy(() => import('../pages/screener/Categories')));
// const SreenerScreenFilms = Loadable(lazy(() => import('../pages/screener/BlogPosts')));

const ScreenerProfile = Loadable(lazy(() => import('../pages/screener/ScreenerProfile')));
// const ScreenerCards = Loadable(lazy(() => import('../pages/screener/UserCards')));
// const ScreenerList = Loadable(lazy(() => import('../pages/screener/UserList')));
const ScreenerAccount = Loadable(lazy(() => import('../pages/screener/ScreenerAccount')));

const ScreenerChat = Loadable(lazy(() => import('../pages/screener/ScreenerChat')));
const ScreenerMail = Loadable(lazy(() => import('../pages/screener/ScreenerMail')));
// const ScreenerCalendar = Loadable(lazy(() => import('../pages/screener/Calendar')));

// Admin
const AdminDash = Loadable(lazy(() => import('../pages/admin/GeneralApp')))
const GeneralAdmin = Loadable(lazy(() => import('../pages/admin/AdminStats')))
const LandingPagePhoto = Loadable(lazy(() => import('../pages/admin/landingPage')))
const Reviews = Loadable(lazy(() => import('../pages/admin/Reviews')))
const AdminUserList = Loadable(lazy(() => import('../pages/admin/UserList')));
const ReviewerUserList = Loadable(lazy(() => import('../pages/admin/ReviewerUserList')));
const CreatorUserList = Loadable(lazy(() => import('../pages/admin/CreatorUserList')));
const AdminList = Loadable(lazy(() => import('../pages/admin/AdminUserList')));
const EditUser = Loadable(lazy(() => import('../pages/admin/EditUser')));
const AdminProfile = Loadable(lazy(() => import('../pages/admin/AdminProfile')));
const AdminAccount = Loadable(lazy(() => import('../pages/admin/AdminAccount')));
const Create = Loadable(lazy(() => import('../pages/admin/Create')));
const Logs = Loadable(lazy(() => import('../pages/admin/LogsPage')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const MainSpotlight = Loadable(lazy(() => import('../pages/MainSpotlight')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const TermsConditions = Loadable(lazy(() => import('../pages/TermsConditions')));
const Info = Loadable(lazy(() => import('../pages/Info')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const DiscorseAsk = Loadable(lazy(() => import('../pages/dashboard/DiscorseAsk/DiscorseAsk')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
// import { isValidToken, setSession } from 'src/utils/jwt';
import { ajaxUrl } from 'src/config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  contactUs: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null,
  modalState: true
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },
    homeState(state, action) {
      state.isLoading = false;
      state.modalState = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user._id !== action.payload);
      state.userList = deleteUser;
      state.isLoading = false;
    },
    deleteMultiUser(state, action) {
      const deleteUser = filter(state.userList, (user) => !action.payload.includes(user._id));
      state.userList = deleteUser;
      state.isLoading = false;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    acceptFriendRequestSuccess(state, action) {
      state.isLoading = false
      let friendsClone = [...state.friends]
      friendsClone.find(friend => friend._id === action.payload).status = "accepted"
      state.friends = friendsClone
    },

    rejectFriendRequestSuccess(state, action) {
      state.isLoading = false
      let updatedFriendsList = [...state.friends].filter(friend => friend._id !== action.payload)
      state.friends = updatedFriendsList
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // UPDATE SOCIAL LINKS
    udpateSocialLinksSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },
    postContactSuccess(state, action) {
      state.isLoading = false;
      state.contactUs = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${ajaxUrl}/profile/my-account`);
      dispatch(slice.actions.getProfileSuccess(response.data.user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSocialLinks(socialLinks) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${ajaxUrl}/profile/update/social-links`, { ...socialLinks });
      dispatch(slice.actions.udpateSocialLinksSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function postContactUs(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await axios.post(`${ajaxUrl}/user/contact`, { ...value })
      dispatch(slice.actions.postContactSuccess(response.data.profile))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('${ajaxUrl}/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${ajaxUrl}/following/${payload.userId}`, {
        following: payload.following
      });
      dispatch(slice.actions.getFollowersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addFollowing(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${ajaxUrl}/following/addFollowing`, payload);
      dispatch(getUserList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${ajaxUrl}/friend/${payload.userId}`, {
        sender: payload.sender
      });
      dispatch(slice.actions.getFriendsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendFriendRequest(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${ajaxUrl}/friend/sendRequest`, payload);
      dispatch(getFriends({ userId: payload.requestSenderId, sender: true }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function acceptFriendRequest(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${ajaxUrl}/friend/acceptRequest`, payload);
      dispatch(slice.actions.acceptFriendRequestSuccess(payload.requestId));
      dispatch(getUserList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function rejectFriendRequest(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${ajaxUrl}/friend/rejectRequest`, payload);
      dispatch(slice.actions.rejectFriendRequestSuccess(payload.requestId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(ajaxUrl + '/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList(query = '') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data: { data } } = await axios.get(`${ajaxUrl}/user/getUserList${query}`)
      dispatch(slice.actions.getUserListSuccess(data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(ajaxUrl + '/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(ajaxUrl + '/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(ajaxUrl + '/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(ajaxUrl + '/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${ajaxUrl}/user/all`);
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteAUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`${ajaxUrl}/user/deleteUser/${id}`);
      dispatch(slice.actions.deleteUser(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteMultiUsers(selected, callback) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Promise.all(selected.map(async (id) => {
        await axios.post(`${ajaxUrl}/user/deleteUser/${id}`);
      }))
      dispatch(slice.actions.deleteMultiUser(selected));
      callback()
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function handleCreatorSignin(state) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.homeState(state));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

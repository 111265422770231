import { s3, S3_BUCKET } from "../config"
import { setUploadingProgress } from '../redux/slices/uploadingProgress';

const keyGenerator = (name) => {
    let myFile = name?.split('.')
    let fileType = myFile[myFile?.length - 1]
    let key = `${Date.now()}-${Math.random()}-${myFile[0]}.${fileType}`

    return { key, fileType }
}

export const uploadSpotlightImages = async (files, dispatch, keyObject = null) => {
    let mainImage = []
    let subImage = []
    try {
        await Promise.all(files?.mainImage?.map(async file => {
            if (file?.name) {
                let { key } = keyGenerator(file?.name)

                const params = {
                    ACL: 'public-read',
                    Body: file,
                    Bucket: S3_BUCKET,
                    Key: `spotlight/${keyObject?.photoKey || key}`
                };

                let data = await s3.upload(params).promise()
                mainImage.push(data.Location)
            } else {
                mainImage = files?.mainImage
            }
        }))
        await Promise.all(files?.subImage?.map(async file => {
            if (file?.name) {
                let { key } = keyGenerator(file?.name)

                const params = {
                    ACL: 'public-read',
                    Body: file,
                    Bucket: S3_BUCKET,
                    Key: `spotlight/${keyObject?.photoKey || key}`
                };

                let data = await s3.upload(params).promise()
                subImage.push(data.Location)
            } else {
                subImage = files?.subImage
            }
        }))
        return { mainImage, subImage };
    } catch (err) {
        dispatch(setUploadingProgress(0))
        console.log("Image video error", err)
        return err;
    }
}
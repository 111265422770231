import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Box, Divider, MenuItem, Typography } from '@material-ui/core';
// routes
import { PATH_ADMIN, PATH_REVIEWER } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/admin/home'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: PATH_ADMIN.user.profile
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: PATH_ADMIN.user.account
  }
];



// ----------------------------------------------------------------------

export default function AdminAccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)

  const MENU_OPTIONS_REVIEWER = user?.role === "admin" ? MENU_OPTIONS : [
    {
      label: 'Profile',
      icon: personFill,
      linkTo: PATH_REVIEWER.user.profile
    },
    {
      label: 'Settings',
      icon: settings2Fill,
      linkTo: PATH_REVIEWER.user.account
    }
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      setLoading(true)
      await logout();
      setLoading(false)
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };



  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS_REVIEWER
          .map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />

              {option.label}
            </MenuItem>
          ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <LoadingButton fullWidth color="inherit" variant="outlined" loading={loading} onClick={handleLogout}>
            Logout
          </LoadingButton>
        </Box>
      </MenuPopover>
    </>
  );
}

import { Icon } from '@iconify/react';
import { useRef, useState, useCallback, useEffect } from 'react';
import peopleFill from '@iconify/icons-eva/people-fill';
// material
import { alpha } from '@material-ui/core/styles';
import { ListItem, ListItemAvatar, Typography, ListItemText, Avatar } from '@material-ui/core';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
// utils
import axios from 'axios';
import { fToNow } from '../../utils/formatTime';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import BadgeStatus from '../../components/BadgeStatus';
import { MIconButton } from '../../components/@material-extend';
import { ajaxUrl, socket } from '../../config';
import { updateUserData } from '../../redux/slices/chat';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;
const PADDING_ITEM = 2.5;

export default function ContactsPopover() {
  const navigate = useNavigate()
  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const { user } = useAuth();

  const getContacts = useCallback(async () => {
    try {
      let query = "?_id=60dc098dd2ef7257b903cf57";
      user.friends.forEach((v) => {
        query = query+'&&_id=' + v;
      });
      const { data: { data } } = await axios.get(`${ajaxUrl}/user/getUserList${query}`);
      const newData = updateUserData(data)
      if (isMountedRef.current) {
        setContacts(newData);
      }
    } catch (error) {
      console.error(error);
    }
  }, [isMountedRef, user._id]);

  useEffect(() => {
    socket.on('status', data => {
      getContacts()
    });
  }, [])

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const compareAlpha = (a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  }
  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        color={open ? 'primary' : 'default'}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Icon icon={peopleFill} width={20} height={20} />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Typography variant="h6" sx={{ p: PADDING_ITEM }}>
          Contacts <Typography component="span">({contacts.length})</Typography>
        </Typography>

        <Scrollbar sx={{ height: ITEM_HEIGHT * 8 }}>
          {contacts?.map((contact, index) => {
            const { id, name, avatar, status, lastActivity } = contact || {};

            return (
              <ListItem button disableGutters key={id + index} sx={{ px: PADDING_ITEM, height: ITEM_HEIGHT }} 
              onClick={() => navigate(`/${user?.role === 'creator' ? 'dashboard' : user?.role === 'admin' ? 'admin' : 'screener'}/user/profile/${id}`, { replace: true })}
              >
                <ListItemAvatar sx={{ position: 'relative' }}>
                  <Avatar src={avatar} />
                  <BadgeStatus status={status} sx={{ position: 'absolute', right: 1, bottom: 1 }} />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                  primary={name}
                  secondary={status === 'offline' && !!lastActivity && fToNow(lastActivity)}
                />
              </ListItem>
            );
          })}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_SCREENER, PATH_ADMIN, PATH_REVIEWER } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};


export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated && (user?.role === 'creator' || user?.role === 'guest')) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }
  if (isAuthenticated && user?.role === 'screener') {
    return <Navigate to={PATH_SCREENER.root} />;
  }
  if (isAuthenticated && user?.role === 'admin') {
    return <Navigate to={PATH_ADMIN.root} />;
  }
  if (isAuthenticated && user?.role === 'reviewer') {
    return <Navigate to={PATH_REVIEWER.controlPanel.reviews_page} />;
  }

  return <>{children}</>;
}

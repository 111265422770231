/* eslint-disable no-unused-vars */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
// import axios from '../utils/axios';
import axios from 'axios';
import { isValidToken, setSession } from '../utils/jwt';
import { ajaxUrl, socket } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  verificationLink: false
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,


    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    localStorage.setItem("user", JSON.stringify(user))
    // console.log('login state',{ state })
    return {
      ...state,
      isAuthenticated: true.valueOf,
      user
    };
  },
  LOGOUT: (state) => {
    localStorage.removeItem("user")
    socket.off('status')
    // console.log('logut called!')
    return ({
      ...state,
      isAuthenticated: false,
      user: null
    })
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user

    };
  },
  VERIFICATION_LINK: (state, action) => {
    const flag = action.payload;

    return {
      ...state,
      verificationLink: flag
    };
  },
  RESET_PASSWORD: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  UPDATE_PASSWORD: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  VERIFY_CODE: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  UPDATE_PROFILE: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  UPDATE_FAV_FILM: (state, action) => {
    let { favourite, filmId } = action.payload
    let cloneUser = { ...state.user }
    if (favourite) {
      cloneUser?.favouriteFilms?.push(filmId)
    } else {
      let ind = cloneUser?.favouriteFilms?.findIndex(id => id === filmId)
      cloneUser?.favouriteFilms?.splice(ind, 1)
    }
    return {
      ...state,
      user: cloneUser
    }
  },
  UPDATE_ISNEWUSER: (state, action) => {
    let cloneUser = { ...state.user }
    cloneUser.isNewUser = false
    return {
      ...state,
      user: cloneUser
    };
  },
  UPDATE_USER_PPV: (state, action) => {
    let cloneUser = { ...state.user }
    if (action.payload.isTimeUp) {
      cloneUser?.ppvData?.splice(cloneUser.ppvData.findIndex(ppv => ppv.id === action.payload.id), 1)
    } else {
      cloneUser.ppvData.push({
        id: action.payload.id,
        boughtAt: action.payload.boughtAt
      })
    }
    return {
      ...state,
      user: cloneUser
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  googleLogin: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          var user = JSON.parse(localStorage.getItem("user"))
          const response = await axios.get(`${ajaxUrl}/profile/my-account`);

          var { user } = response.data;
          const role = user.role

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(`${ajaxUrl}/user/login`, {
      email,
      password
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      }
    });

    await axios.post(`${ajaxUrl}/user/updateStatus`, {
      userId: user._id,
      status: "online"
    })
  };

  const googleLogin = async (params) => {
    const { email, firstName, lastName, role, uid, photoURL } = params
    const response = await axios.post(`${ajaxUrl}/user/googleLogin`, {
      email,
      firstName,
      lastName,
      role,
      uid,
      photoURL
    });
    const { user, accessToken } = response.data;
    // console.log({googleLogin: user})
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      }
    });
  }
  const register = async (email, password, firstName, lastName, role, stripeCreatorAccountId) => {
    console.log("stripeCreatorAccountId", stripeCreatorAccountId)
    if (role === "creator") {
      const response = await axios.post(`${ajaxUrl}/user/register`, {
        email,
        password,
        firstName,
        lastName,
        role,
        stripeCreatorAccountId: ""


      });
      const { accessToken, user } = response.data;
    } else if (role === "screener") {
      const response = await axios.post(`${ajaxUrl}/user/register`, {
        email,
        password,
        firstName,
        lastName,
        role,


      });
      const { accessToken, user } = response.data;
    }



    dispatch({
      type: 'VERIFICATION_LINK',
      payload: true
    });

    // window.localStorage.setItem('accessToken', accessToken);
    // window.localStorage.setItem('user', JSON.stringify(user));
    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user
    //   }
    // });
  };

  const logout = async () => {
    let user = JSON.parse(localStorage.getItem("user"))
    await axios.post(`${ajaxUrl}/user/updateStatus`, {
      userId: user._id,
      status: "offline"
    })
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = async (email) => {
    const response = await axios.post(`${ajaxUrl}/user/resetPassword`, {
      email
    });
    dispatch({ type: 'RESET_PASSWORD' });
  };

  const updatePassword = async (email, key, password) => {
    const response = await axios.post(`${ajaxUrl}/user/updatePassword`, {
      email,
      key,
      password
    });
    dispatch({ type: 'UPDATE_PASSWORD' });
  };

  const verifyCode = async (email, key) => {
    const response = await axios.post(`${ajaxUrl}/user/verifyCode`, {
      email,
      key
    });
    dispatch({ type: 'VERIFY_CODE' });
  };

  const verifyEmail = async (email, key) => {
    const { data: { user, accessToken, success } } = await axios.post(`${ajaxUrl}/user/email-verification`, {
      email,
      token: key
    });
    console.log(user, accessToken, 'user, accessToken')
    if (success) {
      window.localStorage.setItem('accessToken', accessToken);
      window.localStorage.setItem('user', JSON.stringify(user));
      dispatch({
        type: 'VERIFICATION_LINK',
        payload: false
      });
      dispatch({
        type: 'REGISTER',
        payload: {
          user
        }
      });
    } else {
      return 'error'
    }
  };

  const updateProfile = async (values) => {
    // const response = await axios.put(`${ajaxUrl}/profile/update`, {
    //   ...values
    // });
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    // console.log(axios.defaults.headers.common);
    const response = await axios.put(`${ajaxUrl}/profile/update`, values, config);
    // console.log(response);
    const { accessToken, user } = response.data;
    window.localStorage.setItem('accessToken', accessToken);
    // console.log('auth_context', user)
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: {
        user
      }
    });
  };

  const updateHiringData = async (values) => {
    // const response = await axios.put(`${ajaxUrl}/profile/update`, {
    //   ...values
    // });
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const response = await axios.put(`${ajaxUrl}/profile/update-hire`, values, config);
    // console.log(response);
    const { accessToken, user } = response.data;
    window.localStorage.setItem('accessToken', accessToken);
    console.log('auth_context', user)
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: {
        user
      }
    });
  };

  const favouriteFilmFunc = async (favourite, filmId, userId) => {
    await axios.post(`${ajaxUrl}/user/favouriteFilm`, {
      filmId,
      userId,
      favourite
    })
    dispatch({
      type: 'UPDATE_FAV_FILM',
      payload: {
        favourite,
        filmId
      }
    })
  };

  const updateIsNewUser = async (data) => {
    await axios.post(`${ajaxUrl}/user/updateUser`, data);
    dispatch({
      type: 'UPDATE_ISNEWUSER'
    })
  };

  const updatePpv = async (ppv, isTimeUp, userId) => {
    await axios.post(`${ajaxUrl}/user/updatePpv`, {
      ppvData: ppv,
      isTimeUp,
      userId
    })
    dispatch({
      type: 'UPDATE_USER_PPV',
      payload: {
        ...ppv,
        isTimeUp
      }
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        verifyEmail,
        resetPassword,
        updatePassword,
        verifyCode,
        updateProfile,
        favouriteFilmFunc,
        updateIsNewUser,
        updateHiringData,
        updatePpv,
        googleLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

import { s3, transcoder, awsElasticConfig, S3_BUCKET, S3_TRANSCODED_BUCKET } from "../config"
import { setUploadingProgress } from '../redux/slices/uploadingProgress';

const transcodeVideo = async (key) => {
    let params = {
        PipelineId: awsElasticConfig.transcode.video.pipelineId,
        Input: {
            Key: `smooth/${key}`,
            FrameRate: 'auto',
            Resolution: 'auto',
            AspectRatio: 'auto',
            Interlaced: 'auto',
            Container: 'auto'
        },
        OutputKeyPrefix: awsElasticConfig.transcode.video.outputKeyPrefix,
        Outputs: awsElasticConfig.transcode.video.presets.map(p => ({
            Key: `${key}${p.suffix}.mp4`,
            PresetId: p.presetId,
            Rotate: 'auto'
        }))
    };

    const transcoderJob = await transcoder.createJob(params).promise()

    return transcoder.waitFor('jobComplete', { Id: transcoderJob.Job.Id }).promise()
};

export const deleteFilmFile = async (key, deleteTranscoded = 1, isImage) => {
    try {
        await s3.deleteObject({
            Bucket: isImage ? S3_BUCKET : (deleteTranscoded ? S3_TRANSCODED_BUCKET : S3_BUCKET),
            Key: `${isImage ? "filmPhoto" : (deleteTranscoded ? "screen_indie_launch" : "smooth")}/${key}`,
        }).promise()

        return true

    } catch (err) {
        console.log("error deleting file", err)
        return false
    }
}

const keyGenerator = (name) => {
    let myFile = name?.split('.')
    let fileType = myFile[myFile?.length - 1]
    let key = `${Date.now()}-${Math.random()}-${myFile[0]}.${fileType}`

    return { key, fileType }
}

export const uploadFilmFiles = async (files, dispatch, keyObject = null, setOtherFormat) => {
    let streamingUrls = []
    let teaser = ''
    try {
        await Promise.all(files?.teaser?.map(async file => {
            if (typeof (file) === 'object') {
                let { key, fileType } = keyGenerator(file?.name || 'empty')

                const params = {
                    ACL: 'public-read',
                    Body: file,
                    Bucket: S3_BUCKET,
                    Key: `smooth/${keyObject?.teaserKey || key}`
                };

                let data = await s3.upload(params).promise()
                if (fileType === "mp4") {
                    teaser = data.Location
                } else {
                    let { Job } = await transcodeVideo(keyObject?.teaserKey || key)
                    await deleteFilmFile(keyObject?.teaserKey || key, 0, false);
                    teaser = `https://screen-indie-video-transcoded.s3.amazonaws.com/screen_indie_launch/${Job.Output.Key}`
                }
            } else {
                teaser = files?.teaser?.[0] || files?.teaser
            }
        }))

        await Promise.all(files?.videos?.map(async file => {
            if (typeof (file) === 'object') {
                let { key, fileType } = keyGenerator(file?.name || 'empty')
                const params = {
                    ACL: 'public-read',
                    Body: file,
                    Bucket: S3_BUCKET,
                    Key: `smooth/${keyObject?.streamingUrlKey || key}`
                };
                await new Promise((resolve, reject) => {
                    s3.upload(params)
                        .on('httpUploadProgress', (progressEvent) => {
                            const { loaded, total } = progressEvent
                            let percent = Math.floor(loaded * 100 / total)
                            dispatch(setUploadingProgress(percent > 1 ? percent - 1 : 1))
                        })
                        .send(async (err, data) => {
                            if (err) reject(err)
                            if (fileType === "mp4") {
                                streamingUrls.push(data?.Location)
                            } else {
                                dispatch(setUploadingProgress(100))
                                setOtherFormat(true)
                                let { Job } = await transcodeVideo(keyObject?.streamingUrlKey || key)
                                await deleteFilmFile(keyObject?.streamingUrlKey || key, 0, false);
                                streamingUrls.push(`https://screen-indie-video-transcoded.s3.amazonaws.com/screen_indie_launch/${Job.Output.Key}`);
                            }
                            resolve("Done")
                        })
                })
            } else {
                streamingUrls = files?.videos
            }
        }))
        console.log({ streamingUrls, teaser, })
        return { streamingUrls, teaser, };
    } catch (err) {
        dispatch(setUploadingProgress(0))
        console.log("upload video error", err)
        return err;
    }
}
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ajaxUrl } from 'src/config';
import { convertToDashed } from 'src/utils/helperFormik';
import { deleteFilmFile } from 'src/utils/uploadFilmFiles';
import { uploadSpotlightImages } from 'src/utils/uploadImages';
import { setUploadingProgress } from './uploadingProgress';

const initialState = {
    isLoading: false,
    error: false,
    spotlights: [],
    spotlight: null
}

const slice = createSlice({
    name: 'film',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET PRODUCT
        getSpotlightsSuccess(state, action) {
            state.isLoading = false;
            state.spotlights = action.payload;
        },
        getSpotlightSuccess(state, action) {
            state.isLoading = false;
            state.spotlight = action.payload;
        },
        // ADD FILM 
        addSpotlightSuccess(state, action) {
            state.isLoading = false;
            state.spotlight = action.payload;
        },
        editSpotlightSuccess(state, action) {
            state.isLoading = false;
            state.spotlight = action.payload;
        },
        deleteSpotlightSuccess(state, action) {
            state.isLoading = false;
            const filtered = [...state.spotlights].filter(v => action.payload._id !== v._id)
            state.spotlights = filtered;

        }
    }
})

export default slice.reducer;

export const getSpotlights = (find) => {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {

            const response = await axios.post(`${ajaxUrl}/spotlight`)
            dispatch(slice.actions.getSpotlightsSuccess(response.data))

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}
export const addSpotlight = (values, user, setSubmitting, enqueueSnackbar, reset) => {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            let { mainImage, subImage } = await uploadSpotlightImages({ mainImage: values.mainImage, subImage: values.subImage }, dispatch)
            const payload = {
                ...values,
                userId: user._id,
                mainImage, subImage,
                vanity: convertToDashed(values.vanity)
            }
            const response = await axios.post(`${ajaxUrl}/spotlight/create`, payload)
            console.log({ payload, abc: convertToDashed(values.title) })
            console.log({ data: response.data })
            dispatch(slice.actions.addSpotlightSuccess(response.data?.data));
            setSubmitting(false);
            enqueueSnackbar(`Create success, Spotlight Uploaded Successfully.`, { variant: 'success' });
            dispatch(setUploadingProgress(0))
            reset()
        } catch (error) {
            setSubmitting(false);
            dispatch(setUploadingProgress(0))
            console.log(error, "spotlight add error")
            dispatch(slice.actions.hasError(error));
        }
    }
}
export const editSpotlight = (values, setSubmitting, enqueueSnackbar, reset, state, setState) => {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const { userId, ...rest } = values
            console.log(state, ' rest')
            let obj = {
                mainImageKey: state?.mainImage?.[0]?.split('/')?.reverse()[0],
                subImageKey: state?.subImage?.[0]?.split('/')?.reverse()[0],
            }
            let { mainImage, subImage } = await uploadSpotlightImages({ mainImage: values.mainImage, subImage: values.subImage }, dispatch, obj)
            const payload = {
                ...rest,
                mainImage, subImage,
                vanity: convertToDashed(values.vanity)
            }
            const response = await axios.post(`${ajaxUrl}/spotlight/update`, payload)
            dispatch(slice.actions.editSpotlightSuccess(response.data?.data));
            setState();
            reset();
            setSubmitting(false);
            enqueueSnackbar(`Update success, Spotlight Updated Successfully.`, { variant: 'success' });
            dispatch(setUploadingProgress(0))
        } catch (error) {
            setSubmitting(false);
            dispatch(setUploadingProgress(0))
            console.log(error, "spotlight add error")
            dispatch(slice.actions.hasError(error));
        }
    }
}
export const deleteSpotlight = (v, handleClose, setDltLoading) => {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            let obj = {
                mainImageKey: v?.mainImage?.[0]?.split('/')?.reverse()[0],
                subImageKey: v?.subImage?.[0]?.split('/')?.reverse()[0],
            }
            await deleteFilmFile(obj.mainImageKey, 1, true)
            await deleteFilmFile(obj.subImageKey, 1, true)
            const resp = await axios.post(`${ajaxUrl}/spotlight/delete/${v._id}`)
            dispatch(slice.actions.deleteSpotlightSuccess(v));
            // setSpotlights(prev => prev?.filter((val) => val._id !== v._id))
            console.log(resp)
            handleClose()
            setDltLoading(false)
        } catch (error) {
            setDltLoading(false)
            dispatch(slice.actions.hasError(error));
        }
    }
}
